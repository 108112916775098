/*
 * @Descripttion:
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2022-08-31 21:26:39
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-11-02 14:53:27
 */

import { BadgeStyle } from "../type/common";
import { ShipmentStatus } from "../type/shipment";

export const getShipmentStatusMap = (
  status: number
): BadgeStyle | undefined => {
  const result = new Map([
    [ShipmentStatus.Created, BadgeStyle.LightPrimary],
    [ShipmentStatus.PickUp, BadgeStyle.LightPrimary],
    [ShipmentStatus.InTransit, BadgeStyle.LightInfo],
    [ShipmentStatus.OutForDelivery, BadgeStyle.LightSuccess],
    [ShipmentStatus.Delivered, BadgeStyle.LightSuccess],
    [ShipmentStatus.Rejected, BadgeStyle.LightDark],
  ]);
  return result.get(status);
};

export const getServiceProviderMap = (status: number): string => {
  const result = new Map([
    [-1, "media/images/zt.png"],
    [1, "media/images/dhl.png"],
    [2, "media/images/ups.png"],
    [3, "media/images/fedex.png"],
    [4, "media/images/sfnl.png"],
    [5, "media/images/sfcn.png"],
    [6, "media/images/gls.png"],
    [7, "media/images/dkg.png"],
    [8, "media/images/tnt.png"],
    [9, "media/images/dpd.png"],
    [10, "media/images/amz.png"],
    [11, "media/images/hms.png"],
    [12, "media/images/sto.png"],
    [13, "media/images/zto.png"],
    [14, "media/images/ems.png"],
    [15, "media/images/swa.png"],
    [16, "media/images/gsl.png"],
    [16, "media/images/dda.png"],
    [18, "media/images/cca.png"],
    [19, "media/images/acs.png"],
    [20, "media/images/wesg.png"],
    [21, "media/images/dhlp.png"],
    [22, "media/images/postnl.png"],
    [23, "media/images/austrigAG.png"],
    [24, "media/images/yto.png"],
    [27, "media/images/dpd.png"],
  ]);
  return result.get(status) || "";
};
